import { CButton, CCol, CContainer, CRow, CFormSelect, CFormInput, CFormCheck, CForm } from "@coreui/react";
import { useState, useEffect, useMemo, useCallback, ChangeEvent, useRef } from "react";
import { BusinessPojo } from "../../../services/Business/BusinessPojo/BusinessPojo";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Language } from "../../../language/English";
import Notification from "../../../services/Notification/Notification";
import Logger from "../../../services/Logger/Logger";
import { PaymentValidation } from "../../../services/Business/PaymentValidation";
import { useParams } from "react-router";
import { BusinessService } from "../../../services/Business/BusinessService";
import { BOOKING_FEE_TYPE_PAY_BY_PERCENTAGE, BOOKING_FEE_TYPE_PAY_BY_RANGE, BOOKING_FEE_TYPE_PAY_PER_BOOKING, BOOKING_FEE_TYPE_PAY_PER_GUEST, BOOKING_FEE_TYPE_ZERO_FEES, BOOKING_PAYMENT_TYPE_FULL, BOOKING_PAYMENT_TYPE_PARTIAL, NOT_SHOW_RANGE_FIELD_FOCUS, RANGE_VALIDATION_FUNCTION_CALL_BY_ADD_MORE, SHOW_RANGE_FIELD_FOCUS } from "../../../services/Business/BusinessConstant";
import Loader from "../../../components/Layout/Loader";
import React from "react";

type FormValues = {
    rangeStart: number | null;
    rangeEnd: number | null;
    rangeValue: number | null;
};

type FormErrors = {
    rangeStart?: string;
    rangeEnd?: string;
    rangeValue?: string;
};

// Define a type for the input field names
type InputFieldNames = 'rangeStart' | 'rangeEnd' | 'rangeValue';

const PaymentForm = () => {
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [paymentType, setPaymentType] = useState<Number>(0);
    const [errorMessageForPaymentType, setErrorMessageForPaymentType] = useState<string>('');
    const [infoMessageForPaymentType, setInfoMessageForPaymentType] = useState<string>('');
    const [errorMessageForBookingFeeType, setErrorMessageForBookingFeeType] = useState<string>('');
    const [errorMessageForBookingFeeValue, setErrorMessageForBookingFeeValue] = useState<string>('');
    const [errorMessageForBookingFeeRange, setErrorMessageForBookingFeeRange] = useState<FormErrors[]>([]);
    const { businessId } = useParams();
    const businessService = useMemo(() => new BusinessService(), []);
    const [ businessDetails, setBusinessDetails] = useState<BusinessPojo>();
    const notification = useMemo(() => new Notification(), []) ;
    const logger = useMemo(() => new Logger(), []) ;
    const [formValues, setFormValues] = useState<FormValues[]>([{ rangeStart: null, rangeEnd: null , rangeValue: null }]);
    const fetchDetails = useCallback( async() : Promise<void> => {
        try{
            if(businessId !== undefined){
                setShowLoader(true);
                const response = await businessService.loadById(parseInt(businessId));
                if(response.isSuccess){
                    setBusinessDetails(response.business);
                } else {
                    notification.error(Language.SOMETHING_IS_WRONG);
                }
                setShowLoader(false);
            } else {
                setBusinessDetails(undefined);
            }
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }, [businessId, businessService, logger, notification]);

    const [focusField, setFocusField] = useState<InputFieldNames | null>(null); // State to track which field to focus on
    
    // Create refs for each input field
    const inputRefs = useRef<{
        [key in InputFieldNames]: (HTMLInputElement | null)[];
    }>({
        rangeStart: [],
        rangeEnd: [],
        rangeValue: [],
    });
    
    
    const formValidation = PaymentValidation();
    const {register, handleSubmit, setValue, watch, formState : {errors}} = useForm<BusinessPojo>({
        resolver : yupResolver(formValidation)
    });
    // Watch the enablePayment checkbox
    const enablePaymentChecked = watch('enablePayment', false);

    // Watch the selected booking fee type
    const bookingFeeType = Number(watch('bookingFeeType'));

    const handleChange = (i: number, e: ChangeEvent<HTMLInputElement>) => {
        let newFormValues = [...formValues];
        newFormValues[i] = {
            ...newFormValues[i],
            [e.target.name]: e.target.value ? Number(e.target.value) : '', // Ensure you're updating the specific key
        };
       
        validateRangeAllFields(newFormValues, NOT_SHOW_RANGE_FIELD_FOCUS, null);
        setValue("bookingFeeType", bookingFeeType);
        setFormValues(newFormValues);
    };

    const validateRangeField = (name: string, value: string): string => {
        let errorMessage = '';  
        if (name === 'rangeStart' || name === 'rangeEnd' || name === 'rangeValue') {
            if (value.trim() === '') {
                errorMessage = Language.THIS_FIELD_REQUIRED;
            } else if (isNaN(Number(value))) {
                errorMessage = Language.THIS_FIELD_MUST_BE_NUMBER;
            } else if (!Number.isInteger(Number(value))) {
                errorMessage = Language.DECIMALS_NOT_SUPPORTED_MESSAGE;
            } else if (Number(value) < 0) {
                errorMessage = Language.NEGATIVE_VALUE_NOT_ALLOWED_MESSAGE;
            }
        }

        return errorMessage; // Return the error message
    };

    const validateRangeAllFields = (values: FormValues[], showFocusType: number, callBy: number|null): boolean => {
        let totalIndex = values.length;
        const validationErrors = values.map((field, index) => {
            return {
                rangeStart: validateRangeField('rangeStart', field.rangeStart !== null ? String(field.rangeStart) : ''),
                rangeEnd: validateRangeField('rangeEnd', field.rangeEnd !== null ? String(field.rangeEnd) : ''),
                rangeValue: validateRangeField('rangeValue', field.rangeValue !== null ? String(field.rangeValue) : ''),
            };
        });
    
        // Check if rangeStart is less than rangeEnd
        validationErrors.forEach((errors, index) => {
            const rangeStartValue = values[index].rangeStart !== null ? Number(values[index].rangeStart) : null;
            const rangeEndValue = values[index].rangeEnd !== null ? Number(values[index].rangeEnd) : null;
            const rangeValue = values[index].rangeValue !== null ? Number(values[index].rangeValue) : null;
            // Check if the last index's rangeEnd
            const lastIndex = totalIndex - 1;
            
            if (rangeValue !== null && rangeEndValue !== null && rangeEndValue !== 0 && rangeValue >= rangeEndValue) {
                errors.rangeValue = `${Language.RANGE_VALUE_MESSAGE_1} ${rangeEndValue} ${Language.RANGE_VALUE_MESSAGE_2} ${Language.RANGE_END}`;
            }

            if (rangeStartValue !== null && rangeEndValue !== null && rangeStartValue >= rangeEndValue) {
                errors.rangeEnd = `${Language.RANGE_MUST_BE_GREATER_THAN_MESSAGE_1} ${rangeStartValue} ${Language.RANGE_MUST_BE_GREATER_THAN_MESSAGE_2}`;
            }

            // Check parent-child relationship
            if (index > 0) { // Assuming that a parent is always before its child
                const parentRangeEnd = Number(values[index - 1].rangeEnd);
                if (parentRangeEnd !== null && rangeStartValue !== null && rangeStartValue !== parentRangeEnd + 1) {
                    errors.rangeStart = `${Language.CHILD_RANGE_MESSAGE_1} ${parentRangeEnd + 1} ${Language.CHILD_RANGE_MESSAGE_2}`;
                }
            }

             // Handle last index separately
            if (index === lastIndex && callBy !== RANGE_VALIDATION_FUNCTION_CALL_BY_ADD_MORE) {
                if (rangeEndValue === null || rangeEndValue === 0) {
                    if (rangeValue !== null && rangeStartValue !== null && rangeStartValue !== 0 && rangeValue >= rangeStartValue) {
                        errors.rangeValue = `${Language.RANGE_VALUE_MESSAGE_1} ${rangeStartValue} ${Language.RANGE_VALUE_MESSAGE_2} ${Language.RANGE_START}`;
                    }
                    errors.rangeEnd = ``;
                }
            }
        });
    
        setErrorMessageForBookingFeeRange(validationErrors);
    
        if (showFocusType === SHOW_RANGE_FIELD_FOCUS) {
            const firstErrorField = Object.keys(validationErrors).find((key) => 
                validationErrors[key as unknown as number].rangeStart ||
                validationErrors[key as unknown as number].rangeEnd ||
                validationErrors[key as unknown as number].rangeValue
            );
    
            if (firstErrorField !== undefined) {
                // Set focusField based on the first error field found
                const firstErrorIndex = Number(firstErrorField);
                const errorKeys = Object.keys(validationErrors[firstErrorIndex]);
                const errorFieldName = errorKeys.find((field) => validationErrors[firstErrorIndex][field as keyof typeof validationErrors[number]]);
    
                setFocusField(errorFieldName as InputFieldNames); // Set focus on the first error field name
            } else {
                setFocusField(null); // Reset if no errors
            }
    
            if (focusField) {
                // Find the first index of the field that has an error
                const errorIndex = errorMessageForBookingFeeRange.findIndex(errors => errors[focusField] !== '');
    
                const fieldToFocus = inputRefs.current[focusField][errorIndex];
                // If an error is found, focus on the corresponding input field
                if (errorIndex !== -1 && fieldToFocus) {
                    // Scroll to the field and then focus on it
                    fieldToFocus?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    fieldToFocus?.focus(); // Focus on the field after scrolling
                }
            }
        }
        
        // Check if there are any errors
        const hasErrors = validationErrors.some(errors => 
            errors.rangeStart || errors.rangeEnd || errors.rangeValue
        );
    
        return !hasErrors; // Return true if no errors, false otherwise
    };
    
    
    const addFormFields = () => {

        if(!validateRangeAllFields(formValues, SHOW_RANGE_FIELD_FOCUS, RANGE_VALIDATION_FUNCTION_CALL_BY_ADD_MORE)){
            return;
        }
        
        // Set booking fee
        setValue("bookingFeeType", bookingFeeType);
    
        if (formValues.length < 100) {
            // Safely extract the rangeEnd and ensure it's a number
            const lastRangeEnd = formValues.length > 0 ? Number(formValues[formValues.length - 1].rangeEnd) : 0;
    
            // Add new field with (lastRangeEnd + 1) as the new rangeStart
            setFormValues([
                ...formValues,
                { rangeStart: lastRangeEnd + 1, rangeEnd: null, rangeValue: null }
            ]);
        } else {
            notification.error(Language.CAN_NOT_ADD_MORE_FIELDS);
        }
    };
    
    const removeFormFields = (i: number) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
    }

    const convertBookingFeeValue = (bookingFeeValue: string | number | null): number | null => {
        let numericBookingFeeValue: number | null;
    
        if (typeof bookingFeeValue === 'string' && bookingFeeValue !== '') {
            numericBookingFeeValue = parseFloat(bookingFeeValue); // Convert string to number
        } else {
            numericBookingFeeValue = bookingFeeValue !== null ? Number(bookingFeeValue) : null; // Handle number or null
        }
    
        return numericBookingFeeValue; // Return the parsed value
    }

    const isValidBookingFeeValue = (bookingFeeType: number|null|string, bookingFeeValue: number|null|string): boolean => {
        let status = true;
        let message = '';
        let numberBookingFeeValue = convertBookingFeeValue(bookingFeeValue);
        setValue("bookingFeeValue", numberBookingFeeValue);
        if (typeof numberBookingFeeValue !== 'number' || isNaN(numberBookingFeeValue)) 
        {
            message = Language.BOOKING_FEE_VALUE_REQUIRED
            status = false;
        }else if (bookingFeeType === BOOKING_FEE_TYPE_PAY_BY_PERCENTAGE ) {
            if (numberBookingFeeValue > 100 || numberBookingFeeValue < 1) {
                message = Language.BOOKING_FEE_VALUE_FOR_PERCENTAGE_MESSAGE ;
                status = false;
            }
        }else if (!Number.isInteger(bookingFeeValue)) {
            message = Language.BOOKING_FEE_VALUE_FOR_NUMERIC_MESSAGE;
            status = false;
        }else if (numberBookingFeeValue < 0) {
            message = Language.BOOKING_FEE_VALUE_FOR_POSITIVE_NUMERIC_MESSAGE;
            status = false;
        }
        
        setErrorMessageForBookingFeeValue(message);
        return status;
    };

    // Handle keyup event for the input
    const handleBookingFeeValueChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        let target = event.currentTarget.value; // This will now be a string
        // Regular expression to check if the number has up to 2 decimal places
        const isValidTwoDecimals = /^\d+(\.\d{0,2})?$/.test(target);
     
        // Only proceed if the number is valid (i.e., up to 2 decimal places)
        if (isValidTwoDecimals || target === '') {
            let value = target !== '' ? Number(target) : null;
            isValidBookingFeeValue(bookingFeeType, value);
        } else {
            // Handle case where the input has more than 2 decimal places
            event.currentTarget.value = parseFloat(target).toFixed(2); // Trim to 2 decimal places
        }
    };

    const isValidBookingFeeType = (bookingPaymentType: number, bookingFeeType: number): boolean => {
        let bookingFeeTypeValidOptions: number[] = [];
        if (bookingPaymentType === BOOKING_PAYMENT_TYPE_PARTIAL) {
            bookingFeeTypeValidOptions = [
                BOOKING_FEE_TYPE_PAY_BY_RANGE,
                BOOKING_FEE_TYPE_PAY_BY_PERCENTAGE,
            ];
        } else if (bookingPaymentType === BOOKING_PAYMENT_TYPE_FULL) {
            bookingFeeTypeValidOptions = [
                BOOKING_FEE_TYPE_ZERO_FEES,
                BOOKING_FEE_TYPE_PAY_BY_PERCENTAGE,
                BOOKING_FEE_TYPE_PAY_PER_BOOKING,
                BOOKING_FEE_TYPE_PAY_PER_GUEST,
            ];
        }
        
        return bookingFeeTypeValidOptions.includes(bookingFeeType || 0);
    };

    // Handle BookingFeeType onChange event
    const handleBookingFeeTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = Number(e.target.value);
        const bookingPaymentType = Number(watch('paymentType'));
        let validBookingFeeType = isValidBookingFeeType(bookingPaymentType, selectedValue);
        setValue("bookingFeeType", selectedValue);
        
        if (!validBookingFeeType) {
            setErrorMessageForBookingFeeType(Language.BOOKING_FEE_TYPE_VALID);
            return;
        }

        if (businessDetails?.bookingFeeType === selectedValue) {
            setValue("bookingFeeValue", businessDetails?.bookingFeeValue);
        } else {
            setValue("bookingFeeValue", null);
        }

        setErrorMessageForBookingFeeType('');
    };

    // Handle handlePaymentTypeChange onChange event
    const handlePaymentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = Number(e.target.value);

        let bookingPaymentTypeValidOptions: number[] = [
            BOOKING_PAYMENT_TYPE_PARTIAL,
            BOOKING_PAYMENT_TYPE_FULL,
        ];
        
        // Check if the selectedValue is in the valid options
        let isValidBookingPaymentType = bookingPaymentTypeValidOptions.includes(selectedValue || 0);

        isValidBookingPaymentType ? setErrorMessageForPaymentType('') : setErrorMessageForPaymentType(Language.BOOKING_PAYMENT_TYPE_VALID);
      
        let validBookingFeeType = isValidBookingFeeType(selectedValue, businessDetails?.bookingFeeType ?? 0);
        setValue("bookingFeeType", validBookingFeeType ? businessDetails?.bookingFeeType || 0 : 0);
        setPaymentType(selectedValue);

        if (BOOKING_PAYMENT_TYPE_PARTIAL === selectedValue) {
            setInfoMessageForPaymentType(Language.PAYMENT_TYPE_PARTIAL_MESSAGE);
        }else if (BOOKING_PAYMENT_TYPE_FULL === selectedValue) {
            setInfoMessageForPaymentType(Language.PAYMENT_TYPE_FULL_MESSAGE);
        }else {
            setInfoMessageForPaymentType('');
        }
    };

    const convertBookingFeeRange = (bookingFeeRange: any): FormValues[] => {
        return bookingFeeRange.map((range: any) => ({
          rangeStart: range.rangeStart !== undefined ? range.rangeStart : null,
          rangeEnd: range.rangeEnd !== undefined ? range.rangeEnd : null,
          rangeValue: range.rangeValue !== undefined ? range.rangeValue : null,
        })) as FormValues[];
    }

    const onSubmit: SubmitHandler<BusinessPojo> = async(data) :Promise<void> => {
        try {
            data.bookingFeeRange = null;
            if (data.enablePayment) {
                if (data?.paymentType !== null && data.paymentType <= 0) 
                {
                    setErrorMessageForPaymentType(Language.BOOKING_PAYMENT_TYPE_VALID);
                    return;
                }

                let validBookingFeeType = isValidBookingFeeType(data.paymentType ?? 0, data?.bookingFeeType ?? 0);
                if (!validBookingFeeType) 
                {
                    setErrorMessageForBookingFeeType(Language.BOOKING_FEE_TYPE_VALID);
                    return;
                }
                
                if (Number(data.bookingFeeType) === BOOKING_FEE_TYPE_PAY_BY_RANGE) 
                {
                    if(!validateRangeAllFields(formValues, SHOW_RANGE_FIELD_FOCUS, null)){
                        return;
                    }

                    data.bookingFeeRange = formValues;
                } else {
                    console.log(data.bookingFeeValue);
                    
                    if (!isValidBookingFeeValue(data.bookingFeeType, data.bookingFeeValue)) 
                    {
                        return;
                    }
                }
                
            } else {
                data.bookingFeeRange = null;
                data.bookingFeeType = null;
                data.bookingFeeValue = null;
                data.paymentType = null;
            }

            setShowLoader(true);
                if(businessDetails?.businessId)
                {
                    data.businessId = businessDetails?.businessId;
                    const response = await businessService.updateBusinessPayment(data);
                    if(response.isSuccess){
                        notification.success(Language.BUSINESS_SUCCESSFULLY_UPDATED);
                        setBusinessDetails(data);
                        if (data?.bookingFeeValue != null && data.bookingFeeType === BOOKING_FEE_TYPE_PAY_BY_RANGE) 
                        {
                            const convertedFormValues = convertBookingFeeRange(data.bookingFeeRange);
                            setFormValues(convertedFormValues);
                        }
                    } else {
                        notification.error(Language.BUSINESS_NOT_UPDATED_TECHNICAL_ERROR);
                    }
                }
            setShowLoader(false);
        } catch (e){
            notification.error(Language.SOMETHING_IS_WRONG);
            setShowLoader(false);
            logger.error(e);
        }
    }

    const getRangeStartValue = (value: unknown) => {
        return Number.isFinite(value) ? Number(value) : '';
    };
    
    useEffect(() => {
        fetchDetails();
    }, [businessId, fetchDetails]);

    useEffect(() => {
        setValue('enablePayment', businessDetails?.enablePayment ?? false);
        let paymentType = businessDetails?.paymentType ?? 0;
        setValue("paymentType", paymentType);
        setPaymentType(paymentType);

        setValue('bookingFeeType', businessDetails?.bookingFeeType ?? 0);
        setValue('bookingFeeRange', businessDetails?.bookingFeeRange ?? null);
        setValue('bookingFeeValue', businessDetails?.bookingFeeValue ?? null);
        
        if (BOOKING_PAYMENT_TYPE_PARTIAL === paymentType) {
            setInfoMessageForPaymentType(Language.PAYMENT_TYPE_PARTIAL_MESSAGE);
        }else if (BOOKING_PAYMENT_TYPE_FULL === paymentType) {
            setInfoMessageForPaymentType(Language.PAYMENT_TYPE_FULL_MESSAGE);
        }else {
            setInfoMessageForPaymentType('');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [businessDetails]);

    useEffect(() => {
        if (
          businessDetails !== undefined &&
          businessDetails.bookingFeeType === BOOKING_FEE_TYPE_PAY_BY_RANGE &&
          businessDetails.bookingFeeRange !== null
        ) {
            const bookingFeeRange = typeof businessDetails.bookingFeeRange === 'string' 
            ? JSON.parse(businessDetails.bookingFeeRange) 
            : businessDetails.bookingFeeRange;
          
          const convertedFormValues = convertBookingFeeRange(bookingFeeRange);
          
      
          setFormValues(convertedFormValues);
        }
      }, [businessDetails]);

   
    return(
        <CForm onSubmit={handleSubmit(onSubmit)}>
            { showLoader ? <Loader /> : null }

            <CContainer className="mt-4 p-3" >
                <CRow>
                    <CCol md={4}>
                        <h5>{Language.PAYMENT}</h5>
                        <hr className="custom_hr"/>
                    </CCol>
                    <CCol md={8}></CCol>

                    <CCol md={12} className="map-image"> 
                        <div> 
                            <div className="mt-1">
                                <CFormCheck {...register('enablePayment')} id="enablePayment" label={Language.ENABLE_PAYMENT}/> 
                            </div>
                        </div>
                    </CCol>
                    
                    {
                        enablePaymentChecked ? <>
                            <CCol md={12}>
                                <hr className="custom_hr"/>
                            </CCol>

                            <CCol md={6} className=""> 
                                <CFormSelect label={Language.BOOKING_PAYMENT_TYPE} {...register("paymentType")} id="paymentType" onChange={handlePaymentTypeChange}>
                                    <option value={0} key={0}>{Language.SELECT} {Language.BOOKING_PAYMENT_TYPE}</option>
                                    <option value={BOOKING_PAYMENT_TYPE_PARTIAL} key={BOOKING_PAYMENT_TYPE_PARTIAL}>{Language.PARTIAL_PAYMENT}</option>
                                    <option value={BOOKING_PAYMENT_TYPE_FULL} key={BOOKING_PAYMENT_TYPE_FULL}>{Language.FULL_PAYMENT}</option>
                                </CFormSelect>
                                {errors.paymentType && <div className="alert alert-danger">{errors.paymentType.message}</div> }
                                {errorMessageForPaymentType && <div className="alert alert-danger">{errorMessageForPaymentType}</div>}
                                {infoMessageForPaymentType && <div className="text-danger">{infoMessageForPaymentType}</div>}
                            </CCol>
                        </>:''
                    }

                    {
                        enablePaymentChecked && Number(paymentType) === BOOKING_PAYMENT_TYPE_FULL ? <>
                            <CCol md={12}>
                                <hr className="custom_hr"/>
                            </CCol>

                            <CCol md={6} className=""> 
                                <CFormSelect label={Language.BOOKING_FEE_TYPE} {...register("bookingFeeType")} id="bookingFeeType" value={bookingFeeType ?? 0} onChange={handleBookingFeeTypeChange}>
                                    <option value={0} key={0}>{Language.SELECT} {Language.BOOKING_FEE_TYPE}</option>
                                    <option value={BOOKING_FEE_TYPE_ZERO_FEES} key={BOOKING_FEE_TYPE_ZERO_FEES}>{Language.ZERO_FEES}</option>
                                    <option value={BOOKING_FEE_TYPE_PAY_BY_PERCENTAGE} key={BOOKING_FEE_TYPE_PAY_BY_PERCENTAGE}>{Language.PAY_BY_PERCENTAGE}</option>
                                    <option value={BOOKING_FEE_TYPE_PAY_PER_BOOKING} key={BOOKING_FEE_TYPE_PAY_PER_BOOKING}>{Language.PAY_PER_BOOKING}</option>
                                    <option value={BOOKING_FEE_TYPE_PAY_PER_GUEST} key={BOOKING_FEE_TYPE_PAY_PER_GUEST}>{Language.PAY_PER_GUEST}</option>
                                </CFormSelect>
                                { errors.bookingFeeType && <div className="alert alert-danger">{errors.bookingFeeType.message}</div> }
                                {errorMessageForBookingFeeType && <div className="alert alert-danger">{errorMessageForBookingFeeType}</div>}
                            </CCol>
                        </>:''
                    }

                    {
                       enablePaymentChecked && Number(paymentType) === BOOKING_PAYMENT_TYPE_PARTIAL ? <>
                            <CCol md={12}>
                                <hr/>
                            </CCol>

                            <CCol md={6} className=""> 
                                <CFormSelect label={Language.BOOKING_FEE_TYPE} {...register("bookingFeeType")} id="bookingFeeType" value={bookingFeeType ?? 0} onChange={handleBookingFeeTypeChange}>
                                    <option value={0} key={0}>{Language.SELECT} {Language.BOOKING_FEE_TYPE}</option>
                                    <option value={BOOKING_FEE_TYPE_PAY_BY_RANGE} key={BOOKING_FEE_TYPE_PAY_BY_RANGE}>{Language.PAY_BY_RANGE}</option>
                                    <option value={BOOKING_FEE_TYPE_PAY_BY_PERCENTAGE} key={BOOKING_FEE_TYPE_PAY_BY_PERCENTAGE}>{Language.PAY_BY_PERCENTAGE}</option>
                                </CFormSelect>
                                { errors.bookingFeeType && <div className="alert alert-danger">{errors.bookingFeeType.message}</div> }
                                {errorMessageForBookingFeeType && <div className="alert alert-danger">{errorMessageForBookingFeeType}</div>}
                            </CCol>
                        </>:''
                    }

                    {
                        enablePaymentChecked && bookingFeeType !== BOOKING_FEE_TYPE_PAY_BY_RANGE && bookingFeeType !== 0 ? <>
                            <CCol md={6} className="mt-3 mt-md-0"> 
                                <CFormInput label={Language.BOOKING_FEE_VALUE}  {...register("bookingFeeValue", { 
                                        setValueAs: v => v === '' ? null : parseFloat(v) // Convert to number, or null if empty
                                    })}  type="number" id="bookingFeeValue" placeholder={Language.BOOKING_FEE_VALUE} step="0.01" onChange={handleBookingFeeValueChanges} />
                                    {errorMessageForBookingFeeValue && <div className="alert alert-danger">{errorMessageForBookingFeeValue}</div>}
                            </CCol>
                        </>:''
                    }

                    {
                        enablePaymentChecked && bookingFeeType === BOOKING_FEE_TYPE_PAY_BY_RANGE ? <>
                            <CCol md={12}>
                                <hr />
                            </CCol>
                            {formValues.map((element, index) => (
                                <React.Fragment key={index}>
                                    <CCol md={12}>
                                    <div className="range-box-parent">    
                                        
                                        <div className="single-range-box">
                                            <CFormInput ref={(el) => (inputRefs.current.rangeStart[index] = el)} type="number" name="rangeStart" label={Language.RANGE_START} placeholder={Language.TYPE_HERE} value={getRangeStartValue(element.rangeStart)} onChange={e => handleChange(index, e)} />
                                            {errorMessageForBookingFeeRange[index]?.rangeStart && <div className="alert alert-danger">{errorMessageForBookingFeeRange[index].rangeStart}</div>}
                                            
                                        </div>    
                                           
                                        <div className="single-range-box">    
                                            <CFormInput ref={(el) => (inputRefs.current.rangeEnd[index] = el)} type="number" name="rangeEnd" label={Language.RANGE_END} placeholder={Language.TYPE_HERE} value={getRangeStartValue(element.rangeEnd)} onChange={e => handleChange(index, e)} />
                                            {errorMessageForBookingFeeRange[index]?.rangeEnd && <div className="alert alert-danger">{errorMessageForBookingFeeRange[index].rangeEnd}</div>}
                                        </div>        
                                            
                                        <div className="single-range-box ">    
                                            <div className="range-value-remove-btn">
                                                <div className="range-value-box">
                                                <CFormInput ref={(el) => (inputRefs.current.rangeValue[index] = el)} type="number" name="rangeValue" label={Language.RANGE_VALUE} placeholder={Language.TYPE_HERE} value={getRangeStartValue(element.rangeValue)} onChange={e => handleChange(index, e)} />
                                                {errorMessageForBookingFeeRange[index]?.rangeValue && <div className="alert alert-danger for-mob">{errorMessageForBookingFeeRange[index].rangeValue}</div>}
                                                </div>
                                                {index !== 0 && index === formValues.length - 1 ? (
                                                    <CButton 
                                                        onClick={() => removeFormFields(index)} 
                                                        className="btn btn-danger btn-sm range-remove-btn"
                                                    >
                                                        {Language.REMOVE}
                                                    </CButton>
                                                    ) : null
                                                }   
                                            </div>
                                            {errorMessageForBookingFeeRange[index]?.rangeValue && <div className="alert alert-danger for-desk">{errorMessageForBookingFeeRange[index].rangeValue}</div>}
                                        </div>
                                    </div>    
                                    </CCol>
                                </React.Fragment>
                            ))}
                            
                            <CCol md={12} className=""> 
                                <CButton  className="btn btn-dark mt-3" onClick={() => addFormFields()}>{Language.ADD}</CButton>
                                <hr></hr> 
                            </CCol>
                        </>:''
                    }
                    
                    <CCol md={12} className="mt-4"> </CCol>
                    <hr></hr>
                    <CCol md={4} className=""> </CCol>
                    <CCol md={4} className=""> 
                        <CButton type="submit" className="submit_button">
                            {
                                Language.SAVE
                            }
                        </CButton>
                    </CCol>
                    <CCol md={4} className=""> 
                    </CCol>
                </CRow>
            </CContainer>
        </CForm>
    )
}
export default PaymentForm;