import { BASE_URL } from "../../Constant";

export const API_ADD_BUSINESS: string = `${BASE_URL}/api/business`
export const API_LOAD_BY_ID_BUSINESS: string = `${BASE_URL}/api/business/`
export const API_UPDATE_BUSINESS: string = `${BASE_URL}/api/business`
export const API_FETCH_ALL_BUSINESSES: string = `${BASE_URL}/api/business`
export const API_SAVE_REVIEW_DETAILS : string = BASE_URL + '/api/business/saveReviewDetails';
export const API_GET_REVIEW_DETAILS : string = BASE_URL + '/api/business/getReviewDetails/';
export const API_BUSINESS_LICENSE_DOCUMENT : string = BASE_URL + '/api/business/license';
export const API_BUSINESS_AGREEMENT_DOCUMENT : string = BASE_URL + '/api/business/agreement';
export const API_ACTIVATE_BUSINESS : string = BASE_URL + '/api/business/activate';
export const API_PAUSE_BUSINESS : string = BASE_URL + '/api/business/pause';
export const API_DELETE_BUSINESS : string = BASE_URL + '/api/business/delete';
export const API_REJECT_BUSINESS : string = BASE_URL + '/api/business/reject';
export const API_IS_URL_EXIST : string = BASE_URL + '/api/business/isUrlExist/';
export const API_UPDATE_IS_AGREEMENT_DONE : string = BASE_URL + '/api/business/updateIsAgreementDone';
export const API_UPDATE_BUSINESS_STATUS : string = BASE_URL + '/api/business/updateStatus';
export const API_BUSINESS_LOAD_SERVICES: string = BASE_URL + '/api/business/loadServices/';
export const API_SAVE_BUSINESS_SEARCH_DETAILS: string = BASE_URL + '/api/business/saveBusinessSearchDetails';
export const API_UPDATE_BUSINESS_NOTIFICATION: string = BASE_URL + '/api/business/updateBookingNotificationDetails';
export const API_UPDATE_BUSINESS_PAYMENT: string = BASE_URL + '/api/business/payment';


export const BUSINESS_DOCUMENT_TYPE_GALLERY_IMAGE: number = 1;
export const BUSINESS_DOCUMENT_TYPE_LICENSE_DOC: number = 2;
export const BUSINESS_DOCUMENT_TYPE_AGREEMENT_DOC: number = 3;
export const BUSINESS_DOCUMENT_TYPE_EMPLOYEE_IMAGE: number = 4;
export const BUSINESS_DOCUMENT_TYPE_EMPLOYEE_IMAGES: number = 5;
export const BUSINESS_DOCUMENT_TYPE_EMPLOYEE_PUBLIC_IMAGE: number = 6;

export const FILE_INPUT_FIELD_NAME_GALLERY: string = 'galleryImages';
export const FILE_INPUT_FIELD_NAME_PRIMARY_IMAGE: string = 'primaryImage';
export const FILE_INPUT_FIELD_NAME_LICENSE_DOCUMENT: string = 'licenseDocument';
export const FILE_INPUT_FIELD_NAME_AGREEMENT_DOCUMENT: string = 'agreementDocument';
export const FILE_INPUT_FIELD_NAME_EMPLOYEE_IMAGE: string = 'employeeImage';
export const FILE_INPUT_FIELD_NAME_EMPLOYEE_IMAGES: string = 'employeeImages';

export const BOOKING_FEE_TYPE_ZERO_FEES : number = 1;
export const BOOKING_FEE_TYPE_PAY_BY_PERCENTAGE : number = 2;
export const BOOKING_FEE_TYPE_PAY_PER_BOOKING : number = 3;
export const BOOKING_FEE_TYPE_PAY_PER_GUEST : number = 4;
export const BOOKING_FEE_TYPE_PAY_BY_RANGE : number = 5;

export const BOOKING_PAYMENT_TYPE_PARTIAL : number = 1;
export const BOOKING_PAYMENT_TYPE_FULL : number = 2;

export const SHOW_RANGE_FIELD_FOCUS : number = 1;
export const NOT_SHOW_RANGE_FIELD_FOCUS : number = 2;

export const RANGE_VALIDATION_FUNCTION_CALL_BY_ADD_MORE : number = 1;


export const NAME_MIN_LENGTH: number = 3;
export const NAME_MAX_LENGTH: number = 195;
export const DESCRIPTION_MIN_LENGTH: number = 10;
export const DESCRIPTION_MAX_LENGTH: number = 900;
export const PHONE_COUNTRY_CODE_MIN_LENGTH: number = 1;
export const PHONE_COUNTRY_CODE_MAX_LENGTH: number = 999;
export const PHONE_NUMBER_MIN_LENGTH: number = 1;
export const PHONE_NUMBER_MAX_LENGTH: number = 999999999999;
export const POSTCODE_MIN_LENGTH: number = 3;
export const POSTCODE_MAX_LENGTH: number = 15;
export const ADDRESS_MAX_LENGTH: number = 250;
export const URL_MIN_LENGTH: number = 3;
export const URL_MAX_LENGTH: number = 190;
export const META_TITLE_MIN_LENGTH: number = 10;
export const META_TITLE_MAX_LENGTH: number = 240;
export const META_DESCRIPTION_MIN_LENGTH: number = 10;
export const META_DESCRIPTION_MAX_LENGTH: number = 950;
export const META_KEYWORD_MIN_LENGTH: number = 10;
export const META_KEYWORD_MAX_LENGTH: number = 290;
export const WEBSITE_MAX_LENGTH: number = 100;
export const LICENSE_FILE_TYPES: string[] = ["application/pdf", "image/jpeg", "image/png", "image/webp"];
export const LICENSE_FILE_SIZE : number = 104857600;



export const DEFAULT_SELECT_COUNTRY: number = 1;
export const DEFAULT_SELECT_REGION: number = 2;

export const DEFAULT_SELECT_BUSINESS_TYPE: number = 5;
export const DEFAULT_SELECT_OPENING_TIME: string = '08:00';
export const DEFAULT_SELECT_CLOSING_TIME: string = '18:00';

export const WEEKDAY_MONDAY_NUMBER: number = 1;
export const WEEKDAY_TUESDAY_NUMBER: number = 2;
export const WEEKDAY_WEDNESDAY_NUMBER: number = 3;
export const WEEKDAY_THURSDAY_NUMBER: number = 4;
export const WEEKDAY_FRIDAY_NUMBER: number = 5;
export const WEEKDAY_SATURDAY_NUMBER: number = 6;
export const WEEKDAY_SUNDAY_NUMBER: number = 7;

export const DUBAI_COUNTRY_CODE: number = 971;

export const DEFAULT_REVIEW_AVERAGE_VALUE : number = 4.5;
export const DEFAULT_REVIEW_VALUE : number = 4;

export const BUSINESS_STATUS_ACTIVE: number = 1;
export const BUSINESS_STATUS_PAUSE: number = 4;
export const BUSINESS_STATUS_INACTIVE: number = 7;
export const BUSINESS_STATUS_DELETED: number = 10;
export const BUSINESS_STATUS_REJECTED: number = 13;


export const BUSINESS_SERVICE_DEFAULT_SELECTED_SERVICE: number = 1;
export const BUSINESS_SERVICE_DEFAULT_SELECTED_DURATION: string = "00:15";
export const BUSINESS_SERVICE_DEFAULT_SELECTED_HAS_SUB_CATEGORY: boolean = false;

export const DEFAULT_EMPLOYEE_AGE: number = 19;

export const GENDER_MALE: number = 1;
export const GENDER_FEMALE: number = 2;
export const GENDER_OTHER: number = 3;

export enum BUSINESS_STATUS { BUSINESS_STATUS_ACTIVE = 1, BUSINESS_STATUS_PAUSE = 4, BUSINESS_STATUS_INACTIVE = 7, BUSINESS_STATUS_DELETED = 10, BUSINESS_STATUS_REJECTED = 13}
export const WHATSAPP_PHONE_NUMBER_MIN_LENGTH: number = 11111111;

export const BUSINESS_LOAD_PER_PAGE = 5;
export const LOAD_BUSINESS_FOR_PAGINATION = 50;